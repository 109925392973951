<template>
  <div>
    <v-container fluid>

      <div class="main">

        <v-row v-if="isLoading">
          <v-col v-for="item in 8" :key="item" cols="12" lg="3">
            <v-card class="rounded-lg shadow">
              <v-card-text>
                <v-skeleton-loader type="list-item-avatar-three-line"></v-skeleton-loader>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <div v-else>
          <v-row>
            <v-col cols="12" lg="3">
              <v-card class="rounded-lg shadow">
                <v-card-text class="py-5">
                  <v-row align="center" justify="center">
                    <v-col cols="3">
                      <v-avatar color="gifty" size=" 45">
                        <v-icon color="white">mdi-clipboard-list-outline</v-icon>
                      </v-avatar>
                    </v-col>
                    <v-col cols="9">
                      <div class="font-weight-black title pb-1 grey--text text--darken-3">
                        {{ total }}
                      </div>
                      Tatal paiements
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" lg="3">
              <v-card class="rounded-lg shadow">
                <v-card-text class="py-5">
                  <v-row align="center" justify="center">
                    <v-col cols="3">
                      <v-avatar color="success" size=" 45">
                        <v-icon color="white">mdi-check</v-icon>
                      </v-avatar>
                    </v-col>
                    <v-col cols="9">
                      <div class="font-weight-black title pb-1 grey--text text--darken-3">
                        {{ done_total }}
                      </div>
                      Validés
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" lg="3">
              <v-card class="rounded-lg shadow">
                <v-card-text class="py-5">
                  <v-row align="center" justify="center">
                    <v-col cols="3">
                      <v-avatar color="warning" size=" 45">
                        <v-icon color="white">mdi-timer-sand</v-icon>
                      </v-avatar>
                    </v-col>
                    <v-col cols="9">
                      <div class="font-weight-black title pb-1 grey--text text--darken-3">
                        {{ pending_total }}
                      </div>
                      En attente
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" lg="3">
              <v-card class="rounded-lg shadow">
                <v-card-text class="py-5">
                  <v-row align="center" justify="center">
                    <v-col cols="3">
                      <v-avatar color="info" size=" 45">
                        <v-icon color="white">mdi-backup-restore</v-icon>
                      </v-avatar>
                    </v-col>
                    <v-col cols="9">
                      <div class="font-weight-black title pb-1 grey--text text--darken-3">
                        {{ refunded_total }}
                      </div>
                      Annulées
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" lg="3">
              <v-card class="rounded-lg shadow">
                <v-card-text class="py-5">
                  <v-row align="center" justify="center">
                    <v-col cols="3">
                      <v-icon color="gifty" large>mdi-currency-eur</v-icon>
                    </v-col>
                    <v-col cols="9">
                      <div class="font-weight-black title pb-1 grey--text text--darken-3 text-no-wrap">
                        {{ CurrencyFormatting(total_amount) }}
                        <span class="f-13">DZD</span>
                      </div>
                      Total
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" lg="3">
              <v-card class="rounded-lg shadow">
                <v-card-text class="py-5">
                  <v-row align="center" justify="center">
                    <v-col cols="3">
                      <v-icon color="success" large>mdi-currency-eur</v-icon>
                    </v-col>
                    <v-col cols="9">
                      <div class="font-weight-black title pb-1 grey--text text--darken-3 text-no-wrap">
                        {{ CurrencyFormatting(total_day_amount) }}
                        <span class="f-13">DZD</span>
                      </div>
                      Aujourd'hui
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" lg="3">
              <v-card class="rounded-lg shadow">
                <v-card-text class="py-5">
                  <v-row align="center" justify="center">
                    <v-col cols="3">
                      <v-icon color="warning" large>mdi-currency-eur</v-icon>
                    </v-col>
                    <v-col cols="9">
                      <div class="d-flex justify-space-between align-center">
                        <div>
                          <div class="font-weight-black title pb-1 grey--text text--darken-3 text-no-wrap">
                            {{ CurrencyFormatting(total_month_amount) }}
                            <span class="f-13">DZD</span>
                          </div>
                          Mois de {{ setMonth(month) }}
                        </div>
                        <v-spacer/>
                        <v-menu bottom
                                right
                                transition="slide-x-transition"
                        >
                          <template v-slot:activator="{ on}">
                            <v-icon color="warning" v-on="on">mdi-chevron-down</v-icon>
                          </template>
                          <v-list dense nav>
                            <v-list-item v-for="(m, i) in months"
                                         :key="i"
                                         :class="m.value === month ? 'warning white--text' : ''"
                                         link
                                         @click="[month=m.value,getPayingBills()]">
                              <v-list-item-title>{{ m.name }}</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </div>

                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" lg="3">
              <v-card class="rounded-lg shadow">
                <v-card-text class="py-5">
                  <v-row align="center" justify="center">
                    <v-col cols="3">
                      <v-icon color="info" large>mdi-currency-eur</v-icon>
                    </v-col>
                    <v-col cols="9">
                      <div class="d-flex justify-space-between align-center">
                        <div>
                          <div class="font-weight-black title pb-1 grey--text text--darken-3 text-no-wrap">
                            {{ CurrencyFormatting(total_year_amount) }}
                            <span class="f-13">DZD</span>
                          </div>
                          Année {{ year }}
                        </div>
                        <v-spacer/>
                        <v-menu bottom
                                right
                                transition="slide-x-transition"
                        >
                          <template v-slot:activator="{ on}">
                            <v-icon color="info" v-on="on">mdi-chevron-down</v-icon>
                          </template>
                          <v-list dense nav>
                            <v-list-item v-for="(y, i) in years"
                                         :key="i"
                                         :class="y === year ? 'info white--text' : ''"
                                         link
                                         @click="[year=y,getPayingBills()]">
                              <v-list-item-title>{{ y }}</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </div>

                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </div>

        <v-card class="rounded-lg shadow mt-3">

          <v-overlay :value="overlay"
                     absolute
                     color="white"
                     opacity="0.5">
            <v-progress-circular
                    color="primary"
                    indeterminate
                    size="50"
            ></v-progress-circular>
          </v-overlay>

          <v-card-title>
            <div>
              <v-text-field
                      v-model="keyword"
                      append-icon="mdi-magnify"
                      clearable
                      dense
                      filled
                      hide-details
                      placeholder="Recherche..."
                      rounded
                      single-line
              ></v-text-field>
            </div>

            <v-spacer/>

            <v-btn color="grey"
                   text
                   @click="$refs.filterDialog.open()">
              <v-icon left>mdi-filter-outline</v-icon>
              Filtre
            </v-btn>

            <v-btn color="grey"
                   icon
                   @click="handleFilter">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>

          </v-card-title>

          <v-divider/>

          <v-card-text v-if="isLoading" class="pa-0">
            <v-skeleton-loader type="table"/>
          </v-card-text>

          <div v-else>

            <v-card-text class="pa-0">

              <v-simple-table v-if=" payingBills.length">
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th>ID.TR</th>
                    <th>Service</th>
                    <th>Compte</th>
                    <th>montant</th>
                    <th class="text-center">Statut</th>
                    <th>Date</th>
                    <th>Validé / Annulé le</th>
                    <th>Actions</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item,i) in  payingBills" :key="i">

                    <td>
                      {{ item.transaction ? item.transaction_id : '-' }}
                    </td>

                    <td class="text-no-wrap font-weight-medium">
                      <v-avatar size="30" tile>
                        <v-img :src="item.bill_info.logo" contain></v-img>
                      </v-avatar>
                      {{ item.transaction.service.name }}
                    </td>

                    <td class="text-no-wrap font-weight-medium pointer"
                        @click="showAccount(item.transaction.account_id)">
                      <v-avatar size="35">
                        <v-img :src="require('@/assets/avatar.png')"/>
                      </v-avatar>
                      {{
                        item.transaction && item.transaction.account && item.transaction.account.user ? item.transaction.account.user.name : "-"
                      }}
                    </td>

                    <td class="font-weight-medium gifty--text">
                      {{
                        item.transaction.amount ? CurrencyFormatting(item.transaction.amount) : '-'
                      }}
                    </td>

                    <td class="text-center">
                      <v-chip :color="item.set_status.color" small>
                        {{ item.set_status.name }}
                      </v-chip>
                    </td>

                    <td class="text-no-wrap">
                      <v-icon :style="{marginTop:'-3px'}"
                              color="gifty"
                              small>mdi-clock
                      </v-icon>
                      {{ dateFormat(item.created_at) }}
                    </td>

                    <td class="text-no-wrap">
                      <v-icon v-if="item.updated_at"
                              :style="{marginTop:'-3px'}"
                              color="primary"
                              small>mdi-clock-alert
                      </v-icon>
                      {{ item.updated_at ? dateFormat(item.updated_at) : '-' }}
                    </td>


                    <td>
                      <div class="d-flex">
                        <v-btn color="gifty" icon @click="$refs.showDialog.open(item)">
                          <v-icon>mdi-eye-plus</v-icon>
                        </v-btn>
                        <v-menu offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                              <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                          </template>
                          <v-list>

                            <v-list-item
                                    :disabled="(item.set_status.value  !== 'pending')"
                                    @click="$refs.validateDialog.open(item)">
                              <v-list-item-icon>
                                <v-icon color="success">mdi-check-circle</v-icon>
                              </v-list-item-icon>
                              <v-list-item-title>Valider</v-list-item-title>
                            </v-list-item>

                            <v-list-item
                                    :disabled="(item.set_status.value !== 'pending')"
                                    @click="$refs.cancelDialog.open(item)">
                              <v-list-item-icon>
                                <v-icon color="info">mdi-backup-restore</v-icon>
                              </v-list-item-icon>
                              <v-list-item-title>Annuler</v-list-item-title>
                            </v-list-item>

                            <v-list-item v-if="item.set_status.value === 'refunded'"
                                         @click="$refs.remarkDialog.open(item)">
                              <v-list-item-icon>
                                <v-icon color="info">mdi-notebook-edit-outline</v-icon>
                              </v-list-item-icon>
                              <v-list-item-title>Remark de l'annulation</v-list-item-title>
                            </v-list-item>

                          </v-list>
                        </v-menu>
                      </div>
                    </td>

                  </tr>
                  </tbody>
                </template>
              </v-simple-table>

              <div v-else>
                <NoResults @reload="getPayingBills"/>
              </div>

            </v-card-text>

            <v-divider v-if="total > 10"/>

            <v-card-actions v-if="total > 10">

              <div :style="{width : '120px'}">
                <v-select v-model="perPage"
                          :items="[10,20,30,50,100]"
                          dense
                          hide-details
                          label="Ligne par page"
                          outlined
                ></v-select>
              </div>

              <v-spacer/>


              <v-pagination v-if="total > 10"
                            v-model="pagination.current"
                            :length="pagination.total"
                            circle
                            total-visible="7"
                            @input="getPayingBills"></v-pagination>

            </v-card-actions>

          </div>

        </v-card>

      </div>

      <ShowDialog ref="showDialog"/>
      <RemarkDialog ref="remarkDialog"/>
      <ValidateDialog ref="validateDialog" @refresh="getPayingBills"/>
      <CancelDialog ref="cancelDialog" @refresh="getPayingBills"/>
      <FilterDialog ref="filterDialog" @filter="handleFilter"/>

    </v-container>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import NoResults from "@/components/NoResults.vue";
import ShowDialog from "./components/ShowDialog.vue";
import RemarkDialog from "./components/RemarkDialog.vue";
import ValidateDialog from "./components/ValidateDialog.vue";
import CancelDialog from "./components/CancelDialog.vue";
import FilterDialog from "./components/FilterDialog.vue";
import moment from "moment";

export default {
    components: {
        ShowDialog,
        RemarkDialog,
        ValidateDialog,
        CancelDialog,
        FilterDialog,
        NoResults,
    },
    data() {
        return {
            id: null,
            isLoading: false,
            overlay: false,

            keyword: null,
            status: null,
            service_id: null,
            dates: [],

            payingBills: [],
            perPage: 10,
            pagination: {
                current: 1,
                total: 0
            },

            total: 0,
            done_total: 0,
            pending_total: 0,
            refunded_total: 0,

            total_amount: 0,
            total_day_amount: 0,
            total_month_amount: 0,
            total_year_amount: 0,

            month: moment().format('MM'),
            year: moment().format('YYYY'),
            currentMonth: moment().format('MM'),
            currentYear: moment().format('YYYY')
        };
    },
    methods: {
        showAccount(account_id) {
            let route = this.$router.resolve({path: "/users/" + account_id})
            window.open(route.href, "_blank")
        },
        dateFormat(date) {
            return moment(date).format('DD/MM/YYYY, HH:mm')
        },
        getPayingBills() {
            this.isLoading = true
            HTTP.get('/v1/payings-bills?page=' + this.pagination.current, {
                params: {
                    perPage: this.perPage,
                    keyword: this.keyword,
                    dates: this.dates,
                    status: this.status,
                    service_id: this.service_id,
                    month: this.month,
                    year: this.year,
                }
            }).then(res => {
                this.isLoading = false
                this.pagination.current = res.data.data.current_page;
                this.pagination.total = res.data.data.last_page;
                this.payingBills = res.data.data.data

                this.total = res.data.data.total;
                this.done_total = res.data.done_total
                this.pending_total = res.data.pending_total
                this.refunded_total = res.data.refunded_total

                this.total_amount = res.data.total_amount
                this.total_day_amount = res.data.total_day_amount
                this.total_month_amount = res.data.total_month_amount
                this.total_year_amount = res.data.total_year_amount


            }).catch(err => {
                this.isLoading = false
                console.log(err)
            })
        },
        handleFilter(e) {
            this.dates = e.dates
            this.status = e.status
            this.service_id = e.service_id
            this.getPayingBills()
        },
        setMonth(month) {
            return this.months.find(el => el.value === month).name
        },
        refresh() {
            this.month = moment().format('MM')
            this.year = moment().format('YYYY')
        },
    },
    created() {
        this.getPayingBills()

    },
    watch: {
        perPage() {
            this.pagination.current = 1
            this.getPayingBills()
        },
        keyword() {
            this.getPayingBills()
        }
    },
    computed: {
        months() {
            return [
                {name: 'Janvier', value: "01"},
                {name: 'Février', value: "02"},
                {name: 'Mars', value: "03"},
                {name: 'Avril', value: "04"},
                {name: 'Mai', value: "05"},
                {name: 'Juin', value: "06"},
                {name: 'Juillet', value: "07"},
                {name: 'Août', value: "08"},
                {name: 'Septembre', value: "09"},
                {name: 'Octobre', value: "10"},
                {name: 'Novombre', value: "11"},
                {name: 'Décembre', value: "12"},
            ]
        },
        years() {
            return ["2023", "2024", "2025", "2026", "2027", "2028", "2029", "2030"]
        },
    },

};
</script>

<style scoped>

.order {
    background-color: rgba(252, 243, 116, 0.945);
}
</style>
