<template>
  <div>
    <v-dialog v-model="dialog" width="400">
      <v-card>

        <v-card-title>
          Remarque de l'annulation
          <v-spacer/>
          <v-icon @click="close">mdi-close</v-icon>
        </v-card-title>

        <v-divider/>
        <v-card-text class="pa-8 text-center">
          {{ bill.remark ?? "Aucune Remarque ajoutée lors de l'annulation" }}
        </v-card-text>
      </v-card>
    </v-dialog>


  </div>
</template>

<script>


export default {
    data() {
        return {
            dialog: false,
            bill: {},
        }
    },
    methods: {
        open(bill) {
            this.bill = bill
            this.dialog = true
        },
        close() {
            this.dialog = false
        },
    },
}
</script>
<style scoped>

</style>