<template>
  <div>
    <v-dialog v-model="dialog" max-width="500" persistent>
      <v-card :disabled="isLoading" :loading="isLoading" class="text-center">

        <v-card-text class="pa-6">
         <v-icon color="success" size="80">mdi-information-outline</v-icon>

         <h2 class="text--success mt-5">CONFIRMATION</h2>

         <p class="mt-5">
           Etes-vous sûr de vouloir valider cette facture ?
         </p>
       </v-card-text>
        <v-divider/>

        <v-card-text class="pa-6">

          <v-checkbox :error-messages="errors.send_mail"
                        v-model="send_mail"
                        label="Envoyer la facture par mail"/>

         <v-file-input :error-messages="errors.file"
                       :filled="!send_mail"
                       :disabled="!send_mail"
                       @change="onChangeFile"
                       outlined
                       ref="file"
                       dense
                       label="Facture en pdf"/>

         <v-text-field outlined
                       :filled="!send_mail"
                       :disabled="!send_mail"
                       prepend-icon="mdi-email-outline"
                       v-model="bill.customer_email"
                       hide-details="auto"
                       dense
                       label="Email du client"/>

        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-spacer/>
          <v-btn class="rounded-lg text-none" text @click="dialog = false">
            <v-icon left>mdi-close</v-icon>
            Fermer
          </v-btn>
          <v-btn :loading="isLoading"
                 class="rounded-lg text-none"
                 color="success"
                 depressed
                 @click="handleValidate">
            <v-icon left>mdi-check-circle-outline</v-icon>
            Valider
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>


import {HTTP} from "@/http-common";

export default {
    data() {
        return {
            dialog: false,
            isLoading: false,
            send_mail: true,
            file: "",
            bill: {},
            errors: {},
        }
    },
    methods: {
        open(bill) {
            this.errors = {}
            this.bill = bill
            this.send_mail = true
            this.file = ""
            this.dialog = true
            if (this.$refs.file){
                this.$refs.file.reset()
            }
        },
        close() {
            this.dialog = false
        },
        onChangeFile(file) {
            this.file = file
        },
        handleValidate() {
            this.isLoading = true
            this.errors = {}
            let formData = new FormData()
            formData.append('file',this.file)
            formData.append('send_mail',this.send_mail ? 1 : 0)
            formData.append('customer_email',this.bill.customer_email)

            HTTP.post('/v1/payings-bills/' + this.bill.id + '/validate', formData).then(() => {
                this.isLoading = false
                this.dialog = false
                this.$successMessage = "Cette facture a été validé avec succes!"
                this.$emit('refresh')
            }).catch(err => {
                this.isLoading = false
                this.errors = err.response.data.errors
                console.log(err)
            })
        },
    },
}
</script>
<style scoped>

</style>