<template>
  <div>
    <v-dialog v-model="dialog" width="700">
      <v-card>

        <v-card-title>
          Détail de la facture
          <v-spacer/>
          <v-icon @click="close">mdi-close</v-icon>
        </v-card-title>

        <v-divider/>

        <v-card-text class="pa-8">

          <v-simple-table class="table-border">
            <template v-slot:default>
              <tbody>

              <tr class="grey lighten-5">
                <td colspan="2">
                  <div class="py-2 f-17">
                    <v-avatar tile>
                      <v-img :src="bill.bill_info.logo" contain></v-img>
                    </v-avatar>
                    {{ bill.bill_info.service }}
                  </div>
                </td>
              </tr>

              <tr v-if="bill.bill_info && bill.bill_info.invoice_nbr">
                <td class="font-weight-medium text-no-wrap">N° facture</td>
                <td>
                  <div class="grey lighten-4 pa-1 my-2 rounded d-flex justify-space-between  align-center">
                    {{ bill.bill_info.invoice_nbr }}
                    <v-btn color="gifty" icon @click="copy(bill.bill_info.invoice_nbr)">
                      <v-icon small>mdi-content-copy</v-icon>
                    </v-btn>
                  </div>
                </td>
              </tr>

              <tr>
                <td class="font-weight-medium text-no-wrap">Code QR</td>
                <td> {{ bill.bill_info.qr_code }}</td>
              </tr>

              <tr>
                <td class="font-weight-medium  text-no-wrap">Site web</td>
                <td>
                  <a :href="bill.bill_info.web_site" target="_blank">
                    {{ bill.bill_info.web_site }}
                  </a>
                </td>
              </tr>

              <tr>
                <td class="font-weight-medium  text-no-wrap">Montant facture</td>
                <td class="font-weight-medium gifty--text">
                  <div class="grey lighten-4 pa-1 my-2 rounded d-flex justify-space-between  align-center">
                    {{ CurrencyFormatting(bill.transaction.amount) }} DZD
                    <v-btn color="gifty" icon @click="copy(bill.transaction.amount)">
                      <v-icon small>mdi-content-copy</v-icon>
                    </v-btn>
                  </div>
                </td>
              </tr>

              <tr v-if="bill.bill_info && bill.bill_info.ebb">
                <td class="font-weight-medium  text-no-wrap">Clé ebb</td>
                <td>
                  <div class="grey lighten-4 pa-1 my-2 rounded d-flex justify-space-between  align-center">
                    {{ bill.bill_info.ebb }}
                    <v-btn color="gifty" icon @click="copy(bill.bill_info.ebb)">
                      <v-icon small>mdi-content-copy</v-icon>
                    </v-btn>
                  </div>
                </td>
              </tr>

              <tr v-if="bill.bill_info && bill.bill_info.key1">
                <td class="font-weight-medium text-no-wrap">Clé de paiement</td>
                <td>

                  <div class="d-flex justify-space-between">

                    <div class="grey lighten-4 pa-1 my-2 rounded text-no-wrap">
                      {{ bill.bill_info.key1 }}
                      <v-btn color="gifty" icon @click="copy(bill.bill_info.key1)">
                        <v-icon small>mdi-content-copy</v-icon>
                      </v-btn>
                    </div>

                    <div class="grey lighten-4 pa-1 my-2 rounded text-no-wrap">
                      {{ bill.bill_info.key2 }}
                      <v-btn color="gifty" icon @click="copy(bill.bill_info.key2)">
                        <v-icon small>mdi-content-copy</v-icon>
                      </v-btn>
                    </div>


                    <div class="grey lighten-4 pa-1 my-2 rounded text-no-wrap">
                      {{ bill.bill_info.key3 }}
                      <v-btn color="gifty" icon @click="copy(bill.bill_info.key3)">
                        <v-icon small>mdi-content-copy</v-icon>
                      </v-btn>
                    </div>


                    <div class="grey lighten-4 pa-1 my-2 rounded text-no-wrap">
                      {{ bill.bill_info.key4 }}
                      <v-btn color="gifty" icon @click="copy(bill.bill_info.key4)">
                        <v-icon small>mdi-content-copy</v-icon>
                      </v-btn>
                    </div>

                    <div v-if="bill.bill_info.key5" class="grey lighten-4 pa-1 my-2 rounded text-no-wrap">
                      {{ bill.bill_info.key5 }}
                      <v-btn color="gifty" icon @click="copy(bill.bill_info.key5)">
                        <v-icon small>mdi-content-copy</v-icon>
                      </v-btn>
                    </div>

                  </div>


                </td>
              </tr>

              <tr>
                <td class="font-weight-medium  text-no-wrap">Email de client</td>

                <td class="font-weight-medium gifty--text">
                  <div class="grey lighten-4 pa-1 my-2 rounded d-flex justify-space-between  align-center">

                    <a v-if="bill.customer_email"
                       :href="'mailto:'+bill.customer_email">
                      {{ bill.customer_email }}
                    </a>
                    <span v-else>-</span>

                    <v-btn color="gifty" icon @click="copy(bill.customer_email)">
                      <v-icon small>mdi-content-copy</v-icon>
                    </v-btn>

                  </div>
                </td>

              </tr>

              </tbody>
            </template>
          </v-simple-table>

        </v-card-text>

      </v-card>
    </v-dialog>

    <v-snackbar v-model="alert" centered color="success" timeout="800" top>
      <v-icon dark left>mdi-check-circle</v-icon>
      Copié dans le presse-papier.
    </v-snackbar>

  </div>
</template>

<script>


export default {
    data() {
        return {
            dialog: false,
            alert: false,
            bill: {},
        }
    },
    methods: {
        open(bill) {
            this.bill = bill
            this.dialog = true
        },
        close() {
            this.dialog = false
        },
        async copy(text) {
            this.alert = false
            try {
                await navigator.clipboard.writeText(text);
                this.alert = true
            } catch ($e) {
                console.log($e)
            }
        }
    },
}
</script>
<style scoped>

</style>