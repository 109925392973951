<template>
  <div>
    <v-dialog v-model="dialog" max-width="500" persistent>
      <v-card :disabled="isLoading" :loading="isLoading" class="text-center">

        <v-card-text class="pa-6">

          <v-icon color="info" size="80">mdi-information-outline</v-icon>

          <h2 class="text--info mt-5">CONFIRMATION</h2>

          <p class="mt-5">
            Etes-vous sûr de vouloir annuler cette facture ?
          </p>
        </v-card-text>
        <v-divider/>
        <v-card-text class="pa-6">
          <v-textarea v-model="remark" :error-messages="errors.remark"
                      hide-details="auto"
                      label="Entrez un remarque ici(optionnel)" outlined
                      rows="3"></v-textarea>

        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-spacer/>
          <v-btn class="rounded-lg text-none" text @click="dialog = false">
            <v-icon left>mdi-close</v-icon>
            Fermer
          </v-btn>
          <v-btn :loading="isLoading"
                 class="rounded-lg text-none"
                 color="info"
                 depressed
                 @click="handleCancel">
            <v-icon left>mdi-backup-restore</v-icon>
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>


import {HTTP} from "@/http-common";

export default {
    data() {
        return {
            dialog: false,
            isLoading: false,
            remark: "",
            bill: {},
            errors: {},
        }
    },
    methods: {
        open(bill) {
            this.errors = {}
            this.bill = bill
            this.dialog = true
        },
        close() {
            this.dialog = false
        },
        handleCancel() {
            this.isLoading = true
            this.errors = {}
            HTTP.post('/v1/payings-bills/' + this.bill.id + '/cancel', {
                remark: this.remark
            }).then(() => {
                this.isLoading = false
                this.$successMessage = "Cette facture a été annulé avec succes!"
                this.dialog = false
                this.$emit('refresh')
            }).catch(err => {
                this.isLoading = false
                this.errors = err.response.data.errors
                console.log(err)
            })
        },
    },
}
</script>
<style scoped>

</style>